import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENV) && !window.Cypress) {
    Sentry.init({
        Vue,
        dsn: 'https://a89ea827a1f041d9a9968ac53a3b2b29@o527262.ingest.sentry.io/5658864',
        environment: ENV || 'production',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.vueIntegration({
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
